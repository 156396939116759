// Generated by Framer (a387266)

import { addFonts, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["GVXeMGvco"];

const serializationHash = "framer-jY8sW"

const variantClassNames = {GVXeMGvco: "framer-v-22gz7s"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translateY(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "GVXeMGvco", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-22gz7s", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"GVXeMGvco"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXIgVGlnaHQtNTAw", "--framer-font-family": "\"Inter Tight\", \"Inter Tight Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-font-weight": "500", "--framer-letter-spacing": "0.6px", "--framer-line-height": "22px", "--framer-text-color": "var(--extracted-r6o4lv, rgba(29, 29, 31, 0.7))"}}>(1)</motion.p></React.Fragment>} className={"framer-1uvxpsc"} data-framer-name={"Point Number"} fonts={["GF;Inter Tight-500"]} layoutDependency={layoutDependency} layoutId={"IJDehsWYm"} style={{"--extracted-r6o4lv": "rgba(29, 29, 31, 0.7)"}} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-jY8sW.framer-ogos78, .framer-jY8sW .framer-ogos78 { display: block; }", ".framer-jY8sW.framer-22gz7s { height: 22px; overflow: hidden; position: relative; width: 26px; }", ".framer-jY8sW .framer-1uvxpsc { flex: none; height: auto; left: 0px; position: absolute; right: 0px; top: 50%; white-space: pre-wrap; word-break: break-word; word-wrap: break-word; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 22
 * @framerIntrinsicWidth 26
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerpi0eON9dM: React.ComponentType<Props> = withCSS(Component, css, "framer-jY8sW") as typeof Component;
export default Framerpi0eON9dM;

Framerpi0eON9dM.displayName = "Point Number";

Framerpi0eON9dM.defaultProps = {height: 22, width: 26};

addFonts(Framerpi0eON9dM, [{explicitInter: true, fonts: [{family: "Inter Tight", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/intertight/v7/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mjPQ-qWSRToK8EPg.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})